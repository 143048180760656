const recommendeDrecordsLang = {
  messages: {
    tw: {
    },
    en: {
    },
  },
};

export {
  recommendeDrecordsLang
};
